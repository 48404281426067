html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}


* {
    box-sizing: border-box;
    font-family: var(--typography-fontfamily);
}