.page {
    * {
        transition: all 0.25s linear;
    }
}

.l-header {
    min-height: 100vh;
    background-image: url("../assets/header_background.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    * {
        color: white;
    }

    .logo-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .bar {
        width: 100%;
        justify-content: space-between;
    }

    .menu-item {
        padding: 12px 16px;
        position: relative;

        .text {
            font-family: "Work Sans";
        }

        .border {
            height: 3px;
            width: 0%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            background: white;
            transition: all 0.3s linear;
        }

        &:hover {
            .border {
                width: 100%;
            }
        }
    }
}

.menu-floating {
    background: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 2px 2px 16px #00000060;
    position: fixed;
    width: 100%;
    top: 0%;
    left: 0;
    transform: translateY(-150%);
    z-index: 50;

    &[data-open=true] {
        transform: translateY(0%);
    }

    h6 {
        color: #263238;
        font-weight: bold;
    }

    .items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .menu-item {
        margin: 4px 0px;
        width: 100%;
        border-radius: 4px;
        
        .text {
            color: #263238 !important;
            font-weight: bold;
        }

        .border {
            display: none;
        }

        &:hover {
            background: #cfd8dc;
        }
    }
}

.header-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    position: relative;

    .left {
        width: 450px;
    }

    .img-single-box {
        width: 640px;
        height: auto;
        object-fit: cover;
        border-radius: 12px;
        box-shadow: 8px 8px 32px rgba(0, 0, 0, 40%);
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(-10%, -25%);
        transition: all 0.25s linear;
    }
}

.section-about {
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
    position: relative;

    .left {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        z-index: 1;
    }

    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 24px;
        z-index: 2;
    }

    .img-box-objects {
        width: 640px;
        height: auto;
        object-fit: cover;
        border-radius: 12px;
        box-shadow: 8px 8px 32px rgba(0, 0, 0, 40%);
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 32px;
        transform: translate(0%, -50%) rotate(-10deg);
    }
}

.section-stay-organized {
    width: 100%;
    min-height: 100vh;
    background: #fafafa;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 40% auto;
        position: relative;
    }

    .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 24px;
    }

    .right {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px;
        position: relative;
    }

    .hexagon {
        width: 420px;
        height: auto;
        transform: rotate(15deg) translate(20%, 20%);
    }

    .img-boxes {
        position: absolute;
        width: 280px;
        height: auto;
        object-fit: cover;
        border-radius: 12px;
        box-shadow: 8px 8px 32px rgba(0, 0, 0, 40%);
    }
}

.section-link-collaborators {
    @extend .section-about;

    .img-collaborators {
        @extend .img-box-objects;
        transform: translate(0%, -50%) rotate(4deg);
    }
}

.section-start-journey {
    @extend .section-stay-organized;
    position: relative;

    .wrapper {
        z-index: 2;
        display: block;
    }

    .left {
        width: 60%;
    }

    .figure-triangle {
        position: absolute;
        right: 0;
        height: 75%;
        width: auto;
        top: 50%;
        transform: translateY(-50%);
    }
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: #2e2e2e;
    padding: 24px;
    min-height: 160px;

    * {
        color: whitesmoke;
    }
}

@media screen and (max-width: 1208px) {
    .header-content {
        .img-single-box {
            transform: translate(15%, -25%);
        }
    }

    .section-about {
        grid-template-columns: 45% auto;

        .figure-rectangle {
            display: none;
        }

        .img-box-objects {
            transform: translate(-35%, -50%) rotate(-8deg);
        }

        .right {
            padding: 16px;
            max-width: 600px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .header-content {
        .img-single-box {
            transform: translate(25%, -25%);
        }
    }

    .section-about {
        grid-template-columns: 35% auto;

        .img-box-objects {
            transform: translate(-65%, -50%) rotate(-8deg);
        }
    }
}

@media screen and (max-width: 957px) {
    .header-content {
        .img-single-box {
            transform: translate(45%, -25%);
        }
    }

    h4 {
        font-size: 1.6rem !important;
    }

    p {
        font-size: 0.975rem !important;
    }
}

@media screen and (max-width: 830px) {
    .header-content {
        .left {
            width: 400px;
        }
        .img-single-box {
            transform: translate(65%, -25%);
        }
    }

    .section-about {
        grid-template-columns: 35% auto;

        .right {
            width: 100%;
        }

        .img-box-objects {
            transform: translate(-75%, -50%) rotate(-8deg);
        }
    }
}

@media screen and (max-width: 670px) {
    .header-content {
        .img-single-box {
            transform: translate(75%, -25%);
        }
    }

    .section-about {
        grid-template-columns: 10% auto;

        .img-box-objects {
            transform: translate(-95%, -50%) rotate(-4deg);
        }
    }

    .section-stay-organized {
        .wrapper {
            grid-template-columns: 60% auto;
        }

        .hexagon {
            width: 100%;
            height: auto;
            transform: rotate(15deg) translate(10%, 10%);
        }

        .img-boxes {
            width: 70%;
            transform: translate(10%) rotate(10deg);
        }
    }
}

@media screen and (max-width: 610px) {
    .header-content {
        .img-single-box {
            transform: translate(90%, -25%);
        }
    }
}

@media screen and (max-width: 500px) {
    .header-content {
        .img-single-box {
            transform: translate(98%, -25%);
        }
    }

    .section-stay-organized {
        .wrapper {
            grid-template-columns: 80% auto;
        }

        .hexagon {
            width: 200px;
            height: auto;
            position: absolute;
            z-index: 1;
            transform: rotate(15deg) translate(10%, 10%);
        }

        .img-boxes {
            width: 180px;
            z-index: 3;
            transform: translate(10%) rotate(-10deg);
        }
    }
}

@media screen and (max-width: 440px) {
    .header-content {
        .left {
            width: 100%;
        }

        .img-single-box {
            transform: translate(110%, -25%);
        }
    }

    .section-about {
        grid-template-columns: 10px auto;
        column-gap: 24px;

        .img-box-objects {
            transform: translate(calc(-100% + 10px), -50%);
        }
    }

    .section-stay-organized {
        .wrapper {
            grid-template-columns: 99% auto;
        }

        .hexagon {
            width: 200px;
            height: auto;
            position: absolute;
            display: none;
        }

        .img-boxes {
            width: 180px;
            z-index: 3;
            transform: translate(45%, 10%) rotate(-15deg);
        }
    }
}

@media screen and (max-width: 380px) {
    .section-about {
        grid-template-columns: 2px auto;
        column-gap: 8px;

        .right {
            width: 100%;

            h4 {
                font-size: 1.4rem;
            }

            p {
                font-size: 0.875rem;
            }
        }
        .img-box-objects {
            transform: translate(calc(-101% + 1px), -50%);
        }
    }

    .section-stay-organized {
        .wrapper {
            grid-template-columns: 99% auto;
        }

        .img-boxes {
            width: 180px;
            z-index: 3;
            transform: translate(45%, 10%) rotate(1deg);
        }
    }

    .section-start-journey {
        @extend .section-stay-organized;
        position: relative;

        * {
            mix-blend-mode: multiply;
        }

        .left {
            width: 100%;
        }

        .figure-triangle {
            position: absolute;
            right: 0;
            height: 75%;
            width: auto;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
