.main {
    background: var(--palette-background-default);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    --header-height: 56px;
    --side-width: 260px;
}

.main_header {
    width: 100%;
    height: var(--header-height);
    padding: 0px 8px;
    margin: 0;
    background:var(--palette-primary-main);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
}

.logo_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.extra_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.content_wrapper {
    width: 100%;
    height: calc(100vh - var(--header-height) );
    margin-top: var(--header-height);
    display: grid;
    grid-template-columns: var(--side-width) auto;
    column-gap: 8px;
}

.main_side_left {
    width: var(--side-width);
    height: calc(100vh - var(--header-height));
    background: var(--palette-background-paper);
    overflow-y: auto;
    scrollbar-width: none;
}

.side_content {  }

.main_side_content {
    flex-grow: 1;
    overflow: auto;
    scrollbar-width: none;
}

@media screen and (max-width: 900px) {
    
    .content_wrapper {
        grid-template-columns: auto;
    }
}