.box {
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title {
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px;
    text-decoration: none;
    transition: all 250ms cubic-bezier(1, 0, 0, 1);
    border-radius: 24px;
    margin: 0px 8px;

    *[data-role="icon"] {
        margin-right: 4px;
    }

    *[data-role="title"] {
        font-weight: 500;
        text-decoration: none;
        color: var(--palette-text-primary);
    }

    &:hover {
        background: var(--palette-primary-main);
    }
}

.link_active {
    border: 1px solid var(--palette-primary-dark);
}